import { isMobile } from '../utils/index'
export default {
  address: '接待會館：新北市土城區青雲路252號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.596504345868!2d121.45709399350989!3d24.979839233554234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346802c5a4279dd1%3A0x5f5db61bcd3099b6!2zMjM25paw5YyX5biC5Zyf5Z-O5Y2A6Z2S6Zuy6LevMjUy6Jmf!5e0!3m2!1szh-TW!2stw!4v1639121121968!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/hnHWiE36Vofnu77p7',
  phone: '02-2263-5666',
  fbLink: 'https://www.facebook.com/悅峰-I-HOME-103275648265850',
  fbMessage: 'https://m.me/103275648265850',
  caseName: '悅峰 i Home',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '悅峰建設股份有限公司',],
    ['建築設計', '富翊建築師事務所',],
  ],

  gtmCode: ['W53QTB5','PKM73FC'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
