export default [
  /*
  { name: '珍稀地段', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', mobileOffset: 0, type: '' },
  { name: '大師團隊', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', mobileOffset: 0 , type: '' },
  { name: '設計美學', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', mobileOffset: 0, type: '' },
  */
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '', mobileOffset: 0, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0, type: 'btn' },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0, type: 'btn' },
]
